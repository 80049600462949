import lifeForms from './lifeForms';
import './css/style.css';

const { floor, random } = Math;
const aliveFill = '#7f7f7f';
const deadFill = '#fefefe';
const surrFill = '#d76d1c';
const outline = '#f5f5f5';
const cellSize = 5;
const borderWidth = 1;

const getClientWidth = () => {
	const bcr = document.body.getBoundingClientRect();
	return [ bcr.width, bcr.height ];
};

const kill = (ctx, state, _x, _y) => {

	const { grid } = state;
	const x = calcX(config, _x);
	const y = calcY(config, _y);

	drawCell(ctx, deadFill, x, y, cellSize);

	grid[_x][_y].alive = false;
};

const resurr = (ctx, state, _x, _y) => {

	const { grid } = state;
	const x = calcX(config, _x);
	const y = calcY(config, _y);

	drawCell(ctx, aliveFill, x, y, cellSize);

	grid[_x][_y].alive = true;
};

/*const getLiveNeighbors = (config, grid, x, y) => {

	let liveNeighbors = 0;

	const { px, nx, py, ny } = getSurroundingXY(config, x, y);

	if (grid[px][py].alive) {
		liveNeighbors++;
	}
	if (grid[px][y].alive) {
		liveNeighbors++;
	}
	if (grid[px][ny].alive) {
		liveNeighbors++;
	}
	if (grid[x][py].alive) {
		liveNeighbors++;
	}
	if (grid[x][ny].alive) {
		liveNeighbors++;
	}
	if (grid[nx][py].alive) {
		liveNeighbors++;
	}
	if (grid[nx][y].alive) {
		liveNeighbors++;
	}
	if (grid[nx][ny].alive) {
		liveNeighbors++;
	}

	return liveNeighbors;
};*/

const getLiveNeighbors = (config, state, x, y) => {

	const { grid, inPlay } = state;
	const { px, nx, py, ny } = getSurroundingXY(config, x, y);
	let liveNeighbors = 0;

	if (px in inPlay) {
		if (inPlay[px].has(py) && grid[px][py].alive) {
			liveNeighbors++;
		}
		if (inPlay[px].has(y) && grid[px][y].alive) {
			liveNeighbors++;
		}
		if (inPlay[px].has(ny) && grid[px][ny].alive) {
			liveNeighbors++;
		}
	}
	if (inPlay[x].has(py) && grid[x][py].alive) {
		liveNeighbors++;
	}
	if (inPlay[x].has(ny) && grid[x][ny].alive) {
		liveNeighbors++;
	}
	if (nx in inPlay) {
		if (inPlay[nx].has(py) && grid[nx][py].alive) {
			liveNeighbors++;
		}
		if (inPlay[nx].has(y) && grid[nx][y].alive) {
			liveNeighbors++;
		}
		if (inPlay[nx].has(ny) && grid[nx][ny].alive) {
			liveNeighbors++;
		}
	}

	return liveNeighbors;
};

const setInPlay = (config, inPlay, x, y) => {

	const { px, nx, py, ny } = getSurroundingXY(config, x, y);

	if (!(x in inPlay)) {
		inPlay[ x ] = new Set();
	}

	if (!(px in inPlay)) {
		inPlay[ px ] = new Set();
	}

	if (!(nx in inPlay)) {
		inPlay[ nx ] = new Set();
	}

	inPlay[ px ].add(py);
	inPlay[ px ].add(y);
	inPlay[ px ].add(ny);
	inPlay[ x ].add(py);
	inPlay[ x ].add(y);
	inPlay[ x ].add(ny);
	inPlay[ nx ].add(py);
	inPlay[ nx ].add(y);
	inPlay[ nx ].add(ny);
};

const getSurroundingXY = ({ cellsX, cellsY }, x, y) => {

	const px = x === 0 ? cellsX - 1 : x - 1;
	const nx = x === cellsX - 1 ? 0 :  x + 1;
	const py = y === 0 ? cellsY - 1 : y - 1;
	const ny = y === cellsY - 1 ? 0 :  y + 1;

	return { px, nx, py, ny };
};

const calcNextDim = (config, n) => {
	const { borderWidth, cellSize } = config;
	return borderWidth + (borderWidth * n) + (cellSize * n);
};

const calcX = (config, x) => {
	return calcNextDim(config, x);
};

const calcY = (config, y) => {
	return calcNextDim(config, y);
};

const drawCell = (ctx, fill, x, y, width) => {
	ctx.fillStyle = fill;
	ctx.fillRect(x, y, width, width);
};

const initialFill = (config, state) => {

	const {
		ctx,
		width,
		height,
		aliveFill,
		deadFill,
		borderWidth,
		cellSize,
		cellsX,
		cellsY,
	} = config;

	const { init: initState, inPlay, grid } = state;

	for (let i = 0; i < cellsX; i++) {

		const cols = [];

		for (let j = 0; j < cellsY; j++) {

			let alive = false;

			if (initState[i] !== undefined && initState[i][j] !== undefined) {
				alive = initState[i][j];
				setInPlay(config, inPlay, i, j);
			}

			const x = calcX(config, i);
			const y = calcY(config, j);

			if (alive) {
				drawCell(ctx, aliveFill, x, y, cellSize);
			} else {
				drawCell(ctx, deadFill, x, y, cellSize);
			}

			cols.push({ alive });
		}
		grid.push(cols);
	}
};

let config, state, ctx, canvas;

const setup = () => {

	canvas = document.getElementById('game');

	const [ width, height ] = getClientWidth();

	canvas.width = width;
	canvas.height = height;

	ctx = canvas.getContext('2d');
	ctx.fillStyle = outline;
	ctx.fillRect(0, 0, width, height);

	let cellsX = floor(width / cellSize);
	let cellsY = floor(height / cellSize);

	config = {
		ctx,
		width,
		height,
		borderWidth,
		cellSize,
		cellsX,
		cellsY,
		aliveFill,
		deadFill,
	};

	state = {
		init: lifeForms.total_aperiodic,
		inPlay: {},
		grid: [],
	};

	return [ config, state ];
};

const iter = () => {

	let deltas = {};
	let { grid, inPlay } = state;

	for (let _x in inPlay) {
		for (let y of inPlay[ _x ]) {

			const x = parseInt(_x);
			const liveNeighbors = getLiveNeighbors(config, state, x, y);

			if (grid[x][y].alive) {
				if (liveNeighbors < 2 || liveNeighbors > 3) {
					if (!(x in deltas)) {
						deltas[ x ] = {};
					}
					deltas[ x ][ y ] = false;
				}
			} else {
				if (liveNeighbors === 3) {
					if (!(x in deltas)) {
						deltas[ x ] = {};
					}
					deltas[ x ][ y ] = true;
				}
			}
		}
	}

	for (let x in deltas) {
		for (let y in deltas[x]) {
			if (deltas[x][y] === true) {
				resurr(ctx, state, x, y);
			} else if (deltas[x][y] === false) {
				kill(ctx, state, x, y);
			}
		}
	}

	let nextPlay = {};
	for (let x in inPlay) {
		for (let y of inPlay[ x ]) {
			if (grid[x][y].alive) {
				setInPlay(config, nextPlay, parseInt(x), parseInt(y));
			}
		}
	}

	state.inPlay = nextPlay;
};

document.addEventListener('DOMContentLoaded', () => {

	const [ config, state ] = setup();

	initialFill(config, state);

	setInterval(iter, 50);
});
